<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/img/karriere.svg" :alt="$t('kmso.career.title_mobile')" class="is-transparent-2 title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-mobile">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.career.title_mobile') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.career.subtitle_mobile') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/img/karriere.svg" :alt="$t('kmso.career.title_desktop')" class="is-transparent-2 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-desktop">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.career.title_desktop') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.career.subtitle_desktop') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.career.main_title') }}
                    </h3>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-white-background">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            {{ $t('kmso.career.main_text_1') }}
                        </p>
                    </read-more>
                    <br>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.career.main_text_2') }}
                    </p>
                </div>
            </div>
        </section>
        <section class="hero is-medium grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom has-text-centered">
                        {{ $t('kmso.career.initiative_application_title') }}
                    </h3>
                    <div class="columns is-centered is-vcentered">
                        <div class="column" :class="{ 'is-4': !this.isTablet, 'is-6': this.isTablet }">
                            <article class="message is-primary">
                                <div class="message-body">
                                    <figure class="image is-3by2">
                                        <img src="/img/karriere2.svg" :alt="$t('kmso.career.initiative_application_img')">
                                    </figure>
                                </div>
                            </article>
                        </div>
                        <div class="column" :class="{ 'is-4': !this.isTablet, 'is-6': this.isTablet }">
                            <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                                <p class="is-size-5-custom">
                                    {{ $t('kmso.career.initiative_application_text_1') }}
                                    <a href = "mailto: kontakt@kmso.de">{{ $t('kmso.career.initiative_application_text_2') }}</a>
                                    {{ $t('kmso.career.initiative_application_text_3') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <WaveFooter background-custom="grey-background"></WaveFooter>
        <script v-html="jsonld" type="application/ld+json"></script>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import Particles from '../components/particles-stars';
    import JobOffer from '../components/job-offer';

    export default {
        name: "Karriere",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                jsonld: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.app.menu_career"),
                        "item": window.location.protocol + "\/\/" + window.location.host + this.$route.path
                    }]
                }
            }
        },
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
            JobOffer,
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
