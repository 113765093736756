<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/img/impressum.svg" :alt="$t('kmso.imprint.title_mobile')" class="is-transparent-4 title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-mobile">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.imprint.title_mobile') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.imprint.subtitle_mobile') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/img/impressum.svg" :alt="$t('kmso.imprint.title_desktop')" class="is-transparent-4 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-desktop">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.imprint.title_desktop') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.imprint.subtitle_desktop') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class=" is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <h3 class="title title-custom">{{ $t('kmso.imprint.imprint_title') }}</h3>
                        <h4 class="is-size-4-custom"><strong>{{ $t('kmso.imprint.imprint_law_1') }}</strong></h4>
                        <br>
                        <img src="/img/kmso.svg" alt="KMSO" width="112" height="28">
                        <br>
                        <p>KMSO IT-Dienstleistungen GmbH</p>
                        <p>Mainzer Str. 3</p>
                        <p>65719 Hofheim am Taunus</p>
                        <p>{{ $t('kmso.imprint.imprint_country') }}</p>
                        <br>
                        <p>{{ $t('kmso.imprint.imprint_head_office') }}</p>
                        <br>
                        <h4 class="is-size-4-custom"><strong>{{ $t('kmso.imprint.imprint_contact') }}</strong></h4>
                        <br>
                        <div>
                            <div class="icon-color-2">
                                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'phone' }"/> {{ $t('kmso.imprint.imprint_telephone') }}
                            </div>
                            <a href="tel:+4961929379434" rel="nofollow">06192 / 937 94 34</a>
                        </div>
                        <br>
                        <div>
                            <div class="icon-color-1">
                                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }"/> {{ $t('kmso.imprint.imprint_whatsapp') }}
                            </div>
                            <a href="https://wa.me/4915906479447" target="_blank" rel="nofollow">0159 / 064 79 447</a>
                        </div>
                        <br>
                        <div>
                            <div class="icon-color-3">
                                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope' }"/> {{ $t('kmso.imprint.imprint_email') }}
                            </div>
                            <a href="mailto:kontakt@kmso.de" rel="nofollow">kontakt@kmso.de</a>
                        </div>
                        <br>
                        <div>
                            <div class="icon-color-2">
                                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'map-marker-alt' }"/> {{ $t('kmso.imprint.imprint_website') }}
                            </div>
                            <p><router-link :to="{ name: 'startseite' }">www.kmso.de</router-link></p>
                        </div>
                        <br>
                        <h4 class="is-size-4-custom"><strong>{{ $t('kmso.imprint.imprint_managing_directors') }}</strong></h4>
                        <br>
                        <p>Jan Bickel</p>
                        <p>Roman Philipp Berneburg</p>
                        <br>
                        <h4 class="is-size-4-custom"><strong>{{ $t('kmso.imprint.imprint_registration') }}</strong></h4>
                        <br>
                        <p>{{ $t('kmso.imprint.imprint_registration_court') }}: Amtsgericht Frankfurt am Main</p>
                        <p>{{ $t('kmso.imprint.imprint_registration_number') }}: HRB 118127</p>
                        <br>
                        <h4 class="is-size-4-custom"><strong>{{ $t('kmso.imprint.imprint_law_2') }}</strong></h4>
                        <br>
                        <p>DE328699262</p>
                        <br>
                        <h4 class="is-size-4-custom"><strong>{{ $t('kmso.imprint.imprint_mstv') }}</strong></h4>
                        <br>
                        <p>Jan Bickel</p>
                        <p>Roman Philipp Berneburg</p>
                        <br>
                        <p>KMSO IT-Dienstleistungen GmbH</p>
                        <p>Mainzer Str. 3</p>
                        <p>65719 Hofheim am Taunus</p>
                        <p>{{ $t('kmso.imprint.imprint_country') }}</p>
                        <br>
                        <i18n path="kmso.imprint.imprint_mstv_1"
                              tag="p">
                            <router-link :to="{ name: 'blog', params: { page: 1 } }" slot="link1">{{ $t('kmso.imprint.imprint_mstv_2') }}</router-link>
                        </i18n>
                        <br>
                        <h4 class="is-size-4-custom"><strong>{{ $t('kmso.imprint.imprint_dispute_settlement') }}</strong></h4>
                        <br>
                        <i18n path="kmso.imprint.imprint_dispute_settlement_text"
                              tag="p">
                            <a slot="link1" href="https://ec.europa.eu/consumers/odr" target="_blank" rel="nofollow">https://ec.europa.eu/consumers/odr</a>
                        </i18n>
                        <p>{{ $t('kmso.imprint.imprint_dispute_settlement_text_2') }}</p>
                        <br>
                        <h4 class="is-size-4-custom"><strong>{{ $t('kmso.imprint.imprint_consumer_dispute_resolution') }}</strong></h4>
                        <br>
                        <p>{{ $t('kmso.imprint.imprint_consumer_dispute_resolution_text') }}</p>
                        <br>
                        <h4 class="is-size-4-custom"><strong>{{ $t('kmso.imprint.imprint_further_representations') }}</strong></h4>
                        <br>
                        <a href="https://fb.me/kmsodeutschland/" target="_blank">Facebook</a>
                        <br>
                        <a href="https://www.instagram.com/kmso_de/" target="_blank">Instagram</a>
                        <br>
                        <a href="https://www.linkedin.com/company/kmso-it-dienstleistungen-gmbh/" target="_blank">LinkedIn</a>
                        <br>
                        <a href="https://www.xing.com/companies/kmsoit-dienstleistungengmbh" target="_blank">XING</a>
                        <br>
                        <a href="https://twitter.com/kmso_de/" target="_blank">Twitter</a>
                        <br>
                        <a href="https://www.youtube.com/channel/UCxDC0iOzxZUZCiU2tHVBKcg" target="_blank">YouTube</a>
                        <br>
                        <a href="https://www.tiktok.com/@kmso_de" target="_blank">TikTok</a>
                        <br>
                        <a href="https://github.com/kmso-de" target="_blank">GitHub</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container">
                    <div class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            {{ $t('kmso.imprint.legal_notice_title') }}
                        </h3>
                        <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <strong>
                                {{ $t('kmso.imprint.legal_notice_limitation_of_liability') }}
                            </strong>
                        </h4>
                        <br>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                        <p>{{ $t('kmso.imprint.legal_notice_limitation_of_liability_text') }}</p>
                        </read-more>
                        <br>
                        <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <strong>
                                {{ $t('kmso.imprint.legal_notice_external_links') }}
                            </strong>
                        </h4>
                        <br>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                        <p>{{ $t('kmso.imprint.legal_notice_external_links_text') }}</p>
                        </read-more>
                        <br>
                        <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <strong>
                                {{ $t('kmso.imprint.legal_notice_copyright') }}
                            </strong>
                        </h4>
                        <br>
                        <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                   background-shadow="shadow-grey-background">
                        <p>{{ $t('kmso.imprint.legal_notice_copyright_text') }}</p>
                        </read-more>
                        <br>
                        <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <strong>
                                {{ $t('kmso.imprint.legal_notice_data_protection') }}
                            </strong>
                        </h4>
                        <br>
                        <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <router-link :to="{ name: 'datenschutz' }">
                                {{ $t('kmso.imprint.legal_notice_data_protection_url') }} &#8811;
                            </router-link>
                        </p>
                        <br>
                        <h4 class="is-size-4-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <strong>
                                {{ $t('kmso.imprint.legal_notice_picture_credits') }}
                            </strong>
                        </h4>
                        <br>
                        <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <a href="https://undraw.co" target="_blank" rel="nofollow">https://undraw.co</a>
                        </p>
                        <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <a href="https://pixabay.com" target="_blank" rel="nofollow">https://pixabay.com</a>
                        </p>
                        <p :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <a href="https://pexels.com" target="_blank" rel="nofollow">https://pexels.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <WaveFooter background-custom="grey-background"></WaveFooter>
        <script v-html="jsonld" type="application/ld+json"></script>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import Particles from '../components/particles-stars';

    export default {
        name: "Impressum",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                jsonld: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.footer.imprint"),
                        "item": window.location.protocol + "\/\/" + window.location.host + this.$route.path
                    }]
                }
            }
        },
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
