<template>
    <section v-if="this.$pageFullyLoaded">
        <div v-if="this.isMobile">
            <section class="hero is-fullheight sky-gradient-1 has-background hero-main-section-1">
                <div class="pos-absolute display-flex width-100p height-100vh add-horizontal-center hero-main-section-2">
                    <img src="/img/ueberkmso.svg" :alt="$t('kmso.about.title_mobile')" class="is-transparent-2 title-img">
                    <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
                </div>
                <div class="hero-body hero-absolute width-100-height-100 hero-main-section-3">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-mobile">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.about.title_mobile') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.about.subtitle_mobile') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div v-else>
            <section class="hero is-fullheight is-hero-top sky-gradient-1 has-background">
                <img src="/img/ueberkmso.svg" :alt="$t('kmso.about.title_desktop')" class="is-transparent-2 title-img">
            </section>
            <section class="hero is-fullheight">
                <div class="hero-body hero-fixed width-100-height-100">
                    <Particles></Particles>
                    <div class="container has-text-centered" data-aos="fade-up">
                        <div class="hero-box hero-box-background-desktop">
                            <h1 class="title has-text-white">
                                {{ $t('kmso.about.title_desktop') }}
                            </h1>
                            <p></p>
                            <h2 class="subtitle has-text-white">
                                {{ $t('kmso.about.subtitle_desktop') }}
                            </h2>
                            <div class="has-text-centered">
                                <div @click="$moveToAnchor('#allgemein')"
                                     class="button is-primary is-size-5-custom transition-up move-up-on-hover">
                                    {{ $t('kmso.general.lets_go') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WaveBottom :is-mobile="this.isMobile"></WaveBottom>
            </section>
        </div>
        <section id="allgemein" class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.about.who_we_are') }}
                    </h3>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-white-background">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            {{ $t('kmso.about.who_we_are_text') }}
                        </p>
                        <br>
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            {{ $t('kmso.about.who_we_are_text_1') }}
                        </p>
                    </read-more>
                    <div class="columns is-centered is-vcentered">
                        <div class="column is-6 margin-top-ueber-uns">
                            <article class="message">
                                <div class="message-body message-border-color-9 ueber-uns-message-body-padding">
                                    <div class="columns">
                                        <div class="column is-12">
                                            <figure v-if="!this.isTablet" class="image margin-top-ueber-uns-invert">
                                                <img src="/img/jan-bickel.png" alt="Jan Bickel" class="width-200px height-200px border-radius-100p is-horizontal-center">
                                            </figure>
                                            <figure v-else class="image margin-top-ueber-uns-invert-1">
                                                <img src="/img/jan-bickel.png" alt="Jan Bickel" class="width-150px height-150px border-radius-100p is-horizontal-center">
                                            </figure>
                                        </div>
                                    </div>
                                    <div class="columns">
                                        <div class="column is-12">
                                            <div class="has-text-centered">
                                                <p class="is-size-4-custom text-primary">
                                                    Jan Bickel
                                                </p>
                                                <p class="is-size-5-custom">
                                                    {{ $t('kmso.about.co_founder') }}
                                                </p>
                                                <br>
                                                <div class="is-size-5-custom">
                                                    <div>
                                                        <div class="icon-color-2">
                                                            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'phone' }"/> {{ $t('kmso.imprint.imprint_telephone') }}
                                                        </div>
                                                        <a href="tel:+4961929379434" rel="nofollow">06192 / 937 94 34</a>
                                                    </div>
                                                    <br>
                                                    <div>
                                                        <div class="icon-color-1">
                                                            <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }"/> {{ $t('kmso.imprint.imprint_whatsapp') }}
                                                        </div>
                                                        <a href="https://wa.me/4915906479447" target="_blank" rel="nofollow">0159 / 064 79 447</a>
                                                    </div>
                                                    <br>
                                                    <div>
                                                        <div class="icon-color-3">
                                                            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope' }"/> {{ $t('kmso.imprint.imprint_email') }}
                                                        </div>
                                                        <a href="mailto:jan.bickel@kmso.de" rel="nofollow">jan.bickel@kmso.de</a>
                                                    </div>
                                                    <br>
                                                    <div>
                                                        <div class="icon-color-4">
                                                            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'file-pdf' }"/> {{ $t('kmso.about.cv') }}
                                                        </div>
                                                        <a href="/file/cv_jan_bickel.pdf" download="cv_jan_bickel.pdf" rel="nofollow">{{ $t('kmso.about.download') }}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="column is-6 margin-top-ueber-uns">
                            <article class="message">
                                <div class="message-body message-border-color-9 ueber-uns-message-body-padding">
                                    <div class="columns">
                                        <div class="column is-12">
                                            <figure v-if="!this.isTablet" class="image margin-top-ueber-uns-invert">
                                                <img src="/img/roman-berneburg.png" alt="Roman Berneburg" class="width-200px height-200px border-radius-100p is-horizontal-center">
                                            </figure>
                                            <figure v-else class="image margin-top-ueber-uns-invert-1">
                                                <img src="/img/roman-berneburg.png" alt="Roman Berneburg" class="width-150px height-150px border-radius-100p is-horizontal-center">
                                            </figure>
                                        </div>
                                    </div>
                                    <div class="columns">
                                        <div class="column is-12">
                                            <div class="has-text-centered">
                                                <p class="is-size-4-custom text-primary">
                                                    Roman Berneburg
                                                </p>
                                                <p class="is-size-5-custom">
                                                    {{ $t('kmso.about.co_founder') }}
                                                </p>
                                                <br>
                                                <div class="is-size-5-custom">
                                                    <div>
                                                        <div class="icon-color-2">
                                                            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'phone' }"/> {{ $t('kmso.imprint.imprint_telephone') }}
                                                        </div>
                                                        <a href="tel:+4961929379434" rel="nofollow">06192 / 937 94 34</a>
                                                    </div>
                                                    <br>
                                                    <div>
                                                        <div class="icon-color-1">
                                                            <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }"/> {{ $t('kmso.imprint.imprint_whatsapp') }}
                                                        </div>
                                                        <a href="https://wa.me/4915906479412" target="_blank" rel="nofollow">0159 / 064 79 412</a>
                                                    </div>
                                                    <br>
                                                    <div>
                                                        <div class="icon-color-3">
                                                            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope' }"/> {{ $t('kmso.imprint.imprint_email') }}
                                                        </div>
                                                        <a href="mailto:roman.berneburg@kmso.de" rel="nofollow">roman.berneburg@kmso.de</a>
                                                    </div>
                                                    <br>
                                                    <div>
                                                        <div class="icon-color-4">
                                                            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'file-pdf' }"/> {{ $t('kmso.about.cv') }}
                                                        </div>
                                                        <a href="/file/cv_roman_berneburg.pdf" download="cv_roman_berneburg.pdf" rel="nofollow">{{ $t('kmso.about.download') }}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <br>
                    <h4 class="is-size-4-custom"
                        :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        <strong>
                            {{ $t('kmso.about.who_we_are_text_2') }}
                        </strong>
                    </h4>
                    <br>
                    <div class="columns is-centered is-vcentered">
                        <div class="column is-12">
                            <video class="border-radius-4 width-100p" poster="/img/our-office-poster.jpg" controls>
                                <source src="/mov/our-office.mp4" type="video/mp4">
                                {{ $t('kmso.about.video_not_available') }}
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold grey-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-left">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.about.main_title') }}
                    </h3>
                    <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.about.text5') }}
                    </p>
                    <br>
                    <article class="message">
                        <div class="message-header message-header-color-9 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.about.text1') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/ueberuns1.svg" :alt="$t('kmso.about.text2')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            <strong>{{ $t('kmso.about.main_text_1') }}</strong>{{ $t('kmso.about.main_text_2') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="message">
                        <div class="message-header message-header-color-2 is-size-5-custom message-header-center"
                             :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                            <p>
                                {{ $t('kmso.about.text3') }}
                            </p>
                        </div>
                        <div class="message-body is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            <div class="columns is-vcentered">
                                <div class="column is-3">
                                    <img src="/img/ueberuns2.svg" :alt="$t('kmso.about.text3')" class="is-horizontal-center">
                                </div>
                                <div class="column is-9">
                                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                                               background-shadow="shadow-message-background">
                                        <p>
                                            {{ $t('kmso.about.text4') }}
                                        </p>
                                    </read-more>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </section>
        <section class="hero is-medium is-bold white-background foreground">
            <div class="hero-body">
                <div class="container" data-aos="fade-right">
                    <h3 class="title title-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                        {{ $t('kmso.about.services_title') }}
                    </h3>
                    <read-more :is-mobile="this.isMobile" :is-tablet="this.isTablet"
                               background-shadow="shadow-white-background">
                        <p class="is-size-5-custom" :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-justified': !this.isMobile || this.isTablet }">
                            {{ $t('kmso.about.services_text_1') }}
                        </p>
                    </read-more>
                    <br>
                    <div v-if="!this.isTablet" class="columns">
                        <div class="column dark-grey-background border-radius-4">
                            <h4 class="is-size-4-custom"
                                :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <strong>
                                    {{ $t('kmso.services.main_box_1_title') }}
                                </strong>
                            </h4>
                            <br>
                            <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <router-link class="is-size-4-custom" :to="{ name: 'projekt-management' }">
                                    {{ $t('kmso.services.main_box_1_subtitle') }} &#8811;
                                </router-link>
                            </div>
                            <br>
                            <carousel
                                carousel="Leistungen-Projektmanagement"
                                :is-mobile="this.isMobile"
                                :is-tablet="this.isTablet">
                            </carousel>
                        </div>
                        <div class="space-between-columns"></div>
                        <div class="column dark-grey-background border-radius-4">
                            <h4 class="is-size-4-custom"
                                :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <strong>
                                    {{ $t('kmso.services.main_box_2_title') }}
                                </strong>
                            </h4>
                            <br>
                            <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                <router-link class="is-size-4-custom" :to="{ name: 'software-entwicklung' }">
                                    {{ $t('kmso.services.main_box_2_subtitle') }} &#8811;
                                </router-link>
                            </div>
                            <br>
                            <carousel
                                carousel="Leistungen-Software"
                                :is-mobile="this.isMobile"
                                :is-tablet="this.isTablet">
                            </carousel>
                        </div>
                    </div>
                    <div v-else>
                        <div class="columns">
                            <div class="column is-12 dark-grey-background border-radius-4">
                                <h4 class="is-size-4-custom"
                                    :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                    <strong>
                                        {{ $t('kmso.services.main_box_1_title') }}
                                    </strong>
                                </h4>
                                <br>
                                <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                    <router-link class="is-size-4-custom" :to="{ name: 'projekt-management' }">
                                        {{ $t('kmso.services.main_box_1_subtitle') }} &#8811;
                                    </router-link>
                                </div>
                                <br>
                                <carousel
                                    carousel="Leistungen-Projektmanagement"
                                    :is-mobile="false"
                                    :is-tablet="this.isTablet">
                                </carousel>
                            </div>
                        </div>
                        <div class="space-between-columns"></div>
                        <div class="columns">
                            <div class="column is-12 dark-grey-background border-radius-4">
                                <h4 class="is-size-4-custom"
                                    :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                    <strong>
                                        {{ $t('kmso.services.main_box_2_title') }}
                                    </strong>
                                </h4>
                                <br>
                                <div :class="{ 'has-text-left': this.isMobile && !this.isTablet, 'has-text-centered': !this.isMobile || this.isTablet }">
                                    <router-link class="is-size-4-custom" :to="{ name: 'software-entwicklung' }">
                                        {{ $t('kmso.services.main_box_2_subtitle') }} &#8811;
                                    </router-link>
                                </div>
                                <br>
                                <carousel
                                    carousel="Leistungen-Software"
                                    :is-mobile="false"
                                    :is-tablet="this.isTablet">
                                </carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Interesse :is-mobile="this.isMobile" :is-tablet="this.isTablet" direction="fade-left" background-custom="grey-background"></Interesse>
        <WaveFooter background-custom="grey-background"></WaveFooter>
        <script v-html="jsonld" type="application/ld+json"></script>
    </section>
</template>

<script>
    import WaveFooter from '../components/wave-footer-bottom';
    import WaveBottom from '../components/wave-bottom';
    import Particles from '../components/particles-stars';
    import Interesse from '../components/interesse';

    export default {
        name: "Ueber-KMSO",
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                jsonld: {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": this.$i18n.t("kmso.app.menu_about"),
                        "item": window.location.protocol + "\/\/" + window.location.host + this.$route.path
                    }]
                }
            }
        },
        components: {
            WaveFooter,
            WaveBottom,
            Particles,
            Interesse,
        },
        mounted() {
            this.$nextTick(() => {
                this.$allComponentsLoaded(this.isMobile);
            });
        },
    }
</script>

<style scoped>

</style>
