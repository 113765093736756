<template>
    <footer>
        <section class="hero is-medium is-primary foreground">
            <div class="hero-body">
                <div class="container">
                    <div v-if="this.isMobile && !this.isTablet" class="columns">
                        <div class="column is-12">
                            <article class="message is-centered">
                                <div class="message-body message-no-border message-border-color-9">
                                    <div class="columns">
                                        <div class="column is-12">
                                            <div class="footer-icon-space-between">
                                                <a href="https://fb.me/kmsodeutschland/" target="_blank">
                                                    <figure class="image is-1by1 icon-box-small">
                                                        <img src="/img/social/facebook_small.png" alt="Facebook">
                                                    </figure>
                                                </a>
                                                <a href="https://www.instagram.com/kmso_de/" target="_blank">
                                                    <figure class="image is-1by1 icon-box-small">
                                                        <img src="/img/social/instagram_small.png" alt="Instagram">
                                                    </figure>
                                                </a>
                                                <a href="https://www.linkedin.com/company/kmso-it-dienstleistungen-gmbh/" target="_blank">
                                                    <figure class="image is-1by1 icon-box-small">
                                                        <img src="/img/social/linkedin_small.png" alt="LinkedIn">
                                                    </figure>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="columns">
                                        <div class="column is-12">
                                            <div class="footer-icon-space-between">
                                                <a href="https://twitter.com/kmso_de/" target="_blank">
                                                    <figure class="image is-1by1 icon-box-small">
                                                        <img src="/img/social/twitter_small.png" alt="Twitter">
                                                    </figure>
                                                </a>
                                                <a href="https://www.tiktok.com/@kmso_de" target="_blank">
                                                    <figure class="image is-1by1 icon-box-small">
                                                        <img src="/img/social/tiktok_small.png" alt="TikTok">
                                                    </figure>
                                                </a>
                                                <a href="https://github.com/kmso-de" target="_blank">
                                                    <figure class="image is-1by1 icon-box-small">
                                                        <img v-if="this.$theme === this.themes[0]" src="/img/social/github_small_light.png" alt="GitHub">
                                                        <img v-else src="/img/social/github_small.png" alt="GitHub">
                                                    </figure>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div v-else class="columns">
                        <div class="column is-12">
                            <article class="message is-centered">
                                <div class="message-body message-no-border message-border-color-9">
                                    <div class="columns is-centered">
                                        <div class="column is-12">
                                            <div class="footer-icon-space-between">
                                                <a href="https://fb.me/kmsodeutschland/" target="_blank" class="footer-icon-space">
                                                    <figure class="image is-1by1 icon-box-small" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
                                                        <img src="/img/social/facebook_small.png" alt="Facebook">
                                                    </figure>
                                                </a>
                                                <a href="https://www.instagram.com/kmso_de/" target="_blank" class="footer-icon-space">
                                                    <figure class="image is-1by1 icon-box-small" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
                                                        <img src="/img/social/instagram_small.png" alt="Instagram">
                                                    </figure>
                                                </a>
                                                <a href="https://www.linkedin.com/company/kmso-it-dienstleistungen-gmbh/" target="_blank" class="footer-icon-space">
                                                    <figure class="image is-1by1 icon-box-small" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
                                                        <img src="/img/social/linkedin_small.png" alt="LinkedIn">
                                                    </figure>
                                                </a>
                                                <a href="https://twitter.com/kmso_de/" target="_blank" class="footer-icon-space">
                                                    <figure class="image is-1by1 icon-box-small" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
                                                        <img src="/img/social/twitter_small.png" alt="Twitter">
                                                    </figure>
                                                </a>
                                                <a href="https://www.tiktok.com/@kmso_de" target="_blank" class="footer-icon-space">
                                                    <figure class="image is-1by1 icon-box-small" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
                                                        <img src="/img/social/tiktok_small.png" alt="TikTok">
                                                    </figure>
                                                </a>
                                                <a href="https://github.com/kmso-de" target="_blank" class="footer-icon-space">
                                                    <figure class="image is-1by1 icon-box-small" :class="{ 'transition-up move-up-on-hover': !this.isMobile && !this.isTablet }">
                                                        <img v-if="this.$theme === this.themes[0]" src="/img/social/github_small_light.png" alt="GitHub">
                                                        <img v-else src="/img/social/github_small.png" alt="GitHub">
                                                    </figure>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div v-if="this.isMobile && !this.isTablet" class="columns">
                        <div class="column is-12">
                            <article class="message">
                                <div class="message-body message-no-border message-border-color-9">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 has-text-centered">
                                            <p class="is-size-4-custom icon-color-2"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'phone' }" /> {{ $t('kmso.contact.telephone') }}</p>
                                            <a href="tel:+4961929379434" rel="nofollow" class="is-size-5-custom text-blue">06192 / 937 94 34</a>
                                            <br><br>
                                            <p class="is-size-5-custom" v-html="$t('kmso.contact.time')" />
                                            <p class="is-size-5-custom" v-html="$t('kmso.contact.time1')" />
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="column is-12">
                            <article class="message">
                                <div class="message-body message-no-border message-border-color-10">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 has-text-centered">
                                            <p class="is-size-4-custom icon-color-1"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }" /> {{ $t('kmso.contact.whatsapp') }}</p>
                                            <a href="https://wa.me/4915906479447" target="_blank" rel="nofollow" class="is-size-5-custom text-blue">0159 / 064 79 447</a>
                                            <br><br>
                                            <p class="is-size-5-custom" v-html="$t('kmso.contact.time')" />
                                            <p class="is-size-5-custom" v-html="$t('kmso.contact.time1')" />
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="column is-12">
                            <article class="message">
                                <div class="message-body message-no-border message-border-color-11">
                                    <div class="columns is-vcentered">
                                        <div class="column is-12 has-text-centered">
                                            <p class="is-size-4-custom icon-color-3"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope' }" /> {{ $t('kmso.contact.email') }}</p>
                                            <a href="mailto:kontakt@kmso.de" rel="nofollow" class="is-size-5-custom text-blue">kontakt@kmso.de</a>
                                            <br><br>
                                            <p class="is-size-5-custom" v-html="$t('kmso.contact.time')" />
                                            <p class="is-size-5-custom" v-html="$t('kmso.contact.time1')" />
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div v-else class="columns">
                        <div class="column is-4">
                            <a href="tel:+4961929379434" rel="nofollow">
                                <article class="message transition-up move-up-on-hover">
                                    <div class="message-body message-no-border message-border-color-9">
                                        <div class="columns is-vcentered">
                                            <div class="column">
                                                <figure class="image is-3by2">
                                                    <img src="/img/telefon.svg" :alt="$t('kmso.contact.telephone')">
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="columns is-vcentered">
                                            <div class="column has-text-centered">
                                                <p class="is-size-4-custom icon-color-2"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'phone' }" /> {{ $t('kmso.contact.telephone') }}</p>
                                                <p class="is-size-5-custom text-blue">06192 / 937 94 34</p>
                                                <br>
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time')" />
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time1')" />
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </a>
                        </div>
                        <div class="column is-4">
                            <a href="https://wa.me/4915906479447" target="_blank" rel="nofollow">
                                <article class="message transition-up move-up-on-hover">
                                    <div class="message-body message-no-border message-border-color-10">
                                        <div class="columns is-vcentered">
                                            <div class="column">
                                                <figure class="image is-3by2">
                                                    <img src="/img/chat.svg" :alt="$t('kmso.contact.whatsapp')">
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="columns is-vcentered">
                                            <div class="column has-text-centered">
                                                <p class="is-size-4-custom icon-color-1"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }" /> {{ $t('kmso.contact.whatsapp') }}</p>
                                                <p class="is-size-5-custom text-blue">0159 / 064 79 447</p>
                                                <br>
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time')" />
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time1')" />
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </a>
                        </div>
                        <div class="column is-4">
                            <a href="mailto:kontakt@kmso.de" rel="nofollow">
                                <article class="message transition-up move-up-on-hover">
                                    <div class="message-body message-no-border message-border-color-11">
                                        <div class="columns is-vcentered">
                                            <div class="column">
                                                <figure class="image is-3by2">
                                                    <img src="/img/email.svg" :alt="$t('kmso.contact.email')">
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="columns is-vcentered">
                                            <div class="column has-text-centered">
                                                <p class="is-size-4-custom icon-color-3"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope' }" /> {{ $t('kmso.contact.email') }}</p>
                                                <p class="is-size-5-custom text-blue">kontakt@kmso.de</p>
                                                <br>
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time')" />
                                                <p class="is-size-5-custom" v-html="$t('kmso.contact.time1')" />
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </a>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-12">
                            <article class="message is-centered">
                                <div class="message-body message-no-border message-border-color-9 is-size-5-custom">
                                    <div class="columns">
                                        <div class="column is-12 has-text-centered">
                                            <img src="/img/kmso.svg" alt="KMSO" width="112" height="28">
                                            <br>
                                            <router-link :to="{ name: 'datenschutz' }">{{ $t('kmso.footer.data_protection') }}</router-link>
                                            <br>
                                            <router-link :to="{ name: 'impressum' }">{{ $t('kmso.footer.imprint') }}</router-link>
                                            <br>
                                            <router-link :to="{ name: 'kontakt' }">{{ $t('kmso.footer.contact') }}</router-link>
                                            <br>
                                            <br>
                                            <address>
                                                <p>KMSO IT-Dienstleistungen GmbH</p>
                                                <p>Mainzer Str. 3</p>
                                                <p>65719 Hofheim am Taunus</p>
                                                <p>{{ $t('kmso.footer.germany') }}</p>
                                            </address>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div v-if="this.isMobile">
                        <br>
                    </div>
                </div>
            </div>
        </section>
    </footer>
</template>

<script>
    export default {
        name: 'Footer',
        props: [
            'isMobile',
            'isTablet',
        ],
        data() {
            return {
                themes: ['dark', 'light'],
            }
        }
    }
</script>
